import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import { NotesOverviewRoute } from './_index';
import { AddNoteRoute } from './add';
import { EditNoteRoute } from './edit';
import { ViewNoteRoute } from './view';

export interface NoteMatchParams {
  companyId: string;
  noteId?: string;
  userAccountId?: string;
}

export const Notes: React.FC<RouteComponentProps<NoteMatchParams>> = ({
  match,
}) => (
  <Switch>
    <Route exact path={match.path} component={NotesOverviewRoute} />
    <Route exact path={`${match.path}/add`} component={AddNoteRoute} />
    <Route exact path={`${match.path}/:noteId`} component={ViewNoteRoute} />
    <Route
      exact
      path={`${match.path}/:noteId/edit`}
      component={EditNoteRoute}
    />
  </Switch>
);
