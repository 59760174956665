import { ContentContainer } from '@frontend/ui';
import { noteMessages } from 'app/messages/notes';
import { useIntl } from 'components/formats';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { AddNote } from 'features/companies/company/notes/add';
import { Page } from 'features/page';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { EmployeeRouteMatchParams } from '../..';

export const AddNoteRoute: React.FC = () => {
  const { params } = useRouteMatch<EmployeeRouteMatchParams>();
  const { formatMessage } = useIntl();
  const parentLink = `/companies/${params.companyId}/employees/${params.userAccountId}/notes`;

  return (
    <NavigationAnchor
      path={parentLink}
      title={formatMessage(noteMessages.notes)}
    >
      <Page parentLink={parentLink} title={formatMessage(noteMessages.addNote)}>
        <ContentContainer>
          <AddNote />
        </ContentContainer>
      </Page>
    </NavigationAnchor>
  );
};
