/**
 * Ensures that the proposal start date is valid if the current month is chosen.
 * The current month should only be allowed for start date if the current date
 * is before or equal to the 15:th of the month.
 *
 * @param startDate - The proposal start date.
 * @param today - The current date.
 */
export const isProposalDateValidInCurrentMonth = (
  startDate: string,
  today: string,
) => {
  const currentDay = new Date(today).getDate();
  const currentMonth = new Date(today).getMonth();
  const startDateMonth = new Date(startDate).getMonth();

  return currentMonth === startDateMonth ? currentDay <= 15 : true;
};
