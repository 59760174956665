import { ContentContainer } from '@frontend/ui';
import { FormattedMessage } from 'components/formats';
import { Notification } from 'features/companies/company/notification';
import { notificationMessages } from 'features/companies/company/notification/messages';
import { Page } from 'features/page';
import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { MatchParams as CompanyMatchParams } from '..';

export const Notifications: React.FC<
  RouteComponentProps<CompanyMatchParams>
> = ({ match }) => (
  <Page
    title={<FormattedMessage {...notificationMessages.notifications} />}
    parentLink={`/companies/${match.params.companyId}`}
  >
    <ContentContainer>
      <Route exact path={match.path} component={Notification} />
    </ContentContainer>
  </Page>
);
