import {
  compilationErrorsQuery,
  compilationErrorsQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as ProposalMatchParams } from 'app/pages/sme/company/proposal';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import { smeCompilationMessages } from 'features/sme/messages/sme';
import React from 'react';
import { useParams } from 'react-router';

import { COMPILATION_ERRORS_QUERY } from './graphql/queries';
import { ErrorList } from './List';

export const CompilationErrors: React.FC = () => {
  const params = useParams<ProposalMatchParams>();

  const { data } = useQuery<
    compilationErrorsQuery,
    compilationErrorsQueryVariables
  >(COMPILATION_ERRORS_QUERY, {
    errorPolicy: 'all',
    suspend: true,
    variables: {
      id: params.proposalId,
    },
  });

  const errors = data?.proposal?.validity.validCompilation.errors.slice() ?? [];

  if (!errors.length) {
    return null;
  }

  return (
    <NotificationCard
      type="warning"
      title={
        <FormattedMessage {...smeCompilationMessages.couldNotGeneratePrices} />
      }
    >
      <ErrorList errors={errors} />
    </NotificationCard>
  );
};
