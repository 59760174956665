import { defineMessages } from 'react-intl';

export const companyMessages = defineMessages({
  kyc: {
    id: 'company.kyc',
    defaultMessage: 'Känn din kund (KYC)',
  },
  impersonate: {
    id: 'company.impersonate',
    defaultMessage: 'Impersonate',
  },
  search: {
    id: 'company.search',
    defaultMessage: 'Sök företag',
  },
  companies: {
    id: 'company.companies',
    defaultMessage: 'Företag',
  },
  noMatch: {
    id: 'company.noMatch',
    defaultMessage: 'Inga företag hittades',
  },
  loginMethod: {
    id: 'company.loginMethod',
    defaultMessage: 'Inloggningsmetoder',
  },
  loginEditMenu: {
    id: 'company.loginEditMenu',
    defaultMessage: 'Inloggningsalternativ',
  },
  activateEmailLoginHeader: {
    id: 'companyMessages.activateEmailLoginHeader',
    defaultMessage: 'Aktivera inloggning med e-post?',
  },
  activateEmailLoginDesc: {
    id: 'companyMessages.activateEmailLoginDesc',
    defaultMessage:
      'Om inloggning med e-post är aktiverat kan vanliga användare logga in genom att få en länk till sin e-postadress. Administratörer loggar alltid in med BankID.',
  },
  deactivateEmailLoginHeader: {
    id: 'companyMessages.deactivateEmailLoginHeader',
    defaultMessage: 'Inaktivera inlogging med e-post?',
  },
  newsletterSettingsHeader: {
    id: 'companyMessages.newsletterSettingsHeader',
    defaultMessage: 'Nyhetsbrev',
  },
  newsletterSettingsOptOut: {
    id: 'companyMessages.newsletterSettingsOptOut',
    defaultMessage: 'Skicka nyhetsbrev till våra anställda',
  },
  updateCompanyInformation: {
    id: 'common.updateCompanyInformation',
    description: 'Update company information',
    defaultMessage: 'Ändra företagsinformation',
  },
  membershipRole: {
    id: 'workInformationMessages.membershipRole',
    description: 'membership role',
    defaultMessage: 'Systemrättigheter',
  },
  noAdministrators: {
    id: 'workInformationMessages.noAdministrators',
    defaultMessage: 'Inga administratörer',
  },
  activeEmployments: {
    id: 'company.activeEmployments',
    defaultMessage: 'Aktiva anställningar',
  },
  benefitPackages: {
    id: 'company.benefitPackages',
    defaultMessage: 'Förmånsgrupper',
  },
  addAdministrator: {
    id: 'company.addAdministrator',
    defaultMessage: 'Lägg till administratör',
  },
  editAdministrator: {
    id: 'company.editAdministrator',
    defaultMessage: 'Redigera administratör',
  },
  removeAdministrator: {
    id: 'company.removeAdministrator',
    defaultMessage: 'Ta bort administratör',
  },
  administratorEditSuccess: {
    id: 'company.administratorEditSuccess',
    defaultMessage: 'Administratör uppdaterad',
  },
  administratorAddSuccess: {
    id: 'company.administratorAddSuccess',
    defaultMessage: 'Administratör tillagd',
  },
  administratorRemoveSuccess: {
    id: 'company.administratorRemoveSuccess',
    defaultMessage: 'Administratör borttagen',
  },
  confirmRemoveAdministrator: {
    id: 'company.confirmRemoveAdministrator',
    defaultMessage: 'Är du säker på att du vill ta bort administratören?',
  },
  reports: {
    id: 'companyMessages.reports',
    defaultMessage: 'Rapporter',
  },
  emailLanguage: {
    id: 'companyMessages.emailLanguage',
    defaultMessage: 'Språk för kommunikation till anställda',
  },
  addAgreements: {
    id: 'companyMessages.addAgreements',
    defaultMessage: 'Lägg till avtal',
  },
});

export const companySettingsMessages = defineMessages({
  settings: {
    id: 'companySettingsMessages.settings',
    defaultMessage: 'Företagsinställningar',
  },
  information: {
    id: 'companySettingsMessages.information',
    defaultMessage: 'Företagsinformation',
  },
});

export const companyFeatureMessages = defineMessages({
  benefitsTitle: {
    id: 'companyFeatureMessages.benefitsTitle',
    defaultMessage: 'Förmåner',
  },
  benefitsDescription: {
    id: 'companyFeatureMessages.benefitsDescription',
    defaultMessage: 'Förmånsgrupper, förmåner & erbjudanden',
  },
  invoicesTitle: {
    id: 'companyFeatureMessages.invoicesTitle',
    defaultMessage: 'Fakturor',
  },
  invoicesDescription: {
    id: 'companyFeatureMessages.invoicesDescription',
    defaultMessage:
      'Översikt över fakturor & fakturarader samt bokföringsfiler',
  },
  reportsTitle: {
    id: 'companyFeatureMessages.reportsTitle',
    defaultMessage: 'Rapporter',
  },
  reportsDescription: {
    id: 'companyFeatureMessages.reportsDescription',
    defaultMessage: 'Översikt över rapporter för lönetransaktioner',
  },
  settingsTitle: {
    id: 'companyFeatureMessages.settingsTitle',
    defaultMessage: 'Företagsinställningar',
  },
  settingsDescription: {
    id: 'companyFeatureMessages.settingsDescription',
    defaultMessage: 'Företagsinformation, kontoplan, avdelningar, avtal mm.',
  },
  notificationsTitle: {
    id: 'companyFeatureMessages.notificationsTitle',
    defaultMessage: 'Ärenden',
  },
  notificationsDescription: {
    id: 'companyFeatureMessages.notificationsDescription',
    defaultMessage: 'Översikt & hantering av företagsärenden',
  },
  employeesTitle: {
    id: 'companyFeatureMessages.employeesTitle',
    defaultMessage: 'Anställda',
  },
  employeesDescription: {
    id: 'companyFeatureMessages.employeesDescription',
    defaultMessage: 'Översikt, anställningsuppgifter & lägga till anställda',
  },
  salaryExchangesTitle: {
    id: 'companyFeatureMessages.salaryExchangesTitle',
    defaultMessage: 'Löneväxling',
  },
  salaryExchangesDescription: {
    id: 'companyFeatureMessages.salaryExchangesDescription',
    defaultMessage:
      'Översikt, ansökningar, händelser, rapporter & gör beräkningar',
  },
  jobOffersTitle: {
    id: 'companyFeatureMessages.jobOffersTitle',
    defaultMessage: 'Jobb',
  },
  jobOffersDescription: {
    id: 'companyFeatureMessages.jobOffersDescription',
    defaultMessage: 'Se, redigera & skapa jobberbjudanden',
  },
});
