import { Form, Formik, SelectField, TextField } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import { add } from '@frontend/ui/icons';
import { stripSearchParams } from '@frontend/utils';
import { ProposalDefaultBenefitPackage } from 'app/apollo/graphql/types';
import { commonMessages, validationMessages } from 'app/messages/common';
import { MatchParams } from 'app/pages/sme/company/proposal';
import { AssistChip } from 'components/AssistChip';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal } from 'components/Modal';
import {
  smeBenefitPackagesMessages,
  smeDefaultBenefitPackagesMessages,
} from 'features/sme/messages/sme';
import qs from 'query-string';
import React from 'react';
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router';
import * as Yup from 'yup';

import { useProposalReadonly } from '../../../utils/use-proposal-readonly';
import { useSubmit } from './utils/use-submit';

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export interface FormValues {
  defaultBenefitPackage: ProposalDefaultBenefitPackage | '';
  name: string;
  proposalId: string;
}

export const CreateBenefitPackageModal = () => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const history = useHistory();
  const location = useLocation();
  const { params } = useRouteMatch<MatchParams>();

  const { 'create-benefit-package': createBenefitPackage } = qs.parse(
    location.search,
  );

  const onRequestClose = () => {
    stripSearchParams(history, location, ['create-benefit-package']);
  };

  const { submit, submissionError } = useSubmit({ onRequestClose, params });

  const initialValues: FormValues = {
    defaultBenefitPackage: '',
    name: '',
    proposalId: params.proposalId,
  };

  return (
    <Modal isOpen={createBenefitPackage} onRequestClose={onRequestClose}>
      <ModalHeader>
        <FormattedMessage
          {...smeBenefitPackagesMessages.createBenefitPackage}
        />
      </ModalHeader>
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={submit}
        validateOnMount
        validationSchema={validationSchema(intl)}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <ModalBody>
              <p>
                <FormattedMessage
                  {...smeBenefitPackagesMessages.benefitPackageDescription}
                />
              </p>
              <TextField
                dense
                name="name"
                label={<FormattedMessage {...commonMessages.name} />}
                required
              />
              <SelectField
                dense
                name="defaultBenefitPackage"
                fixed
                label={
                  <FormattedMessage
                    {...smeBenefitPackagesMessages.selectDefaultBenefitPackage}
                  />
                }
                options={Object.values(ProposalDefaultBenefitPackage).map(
                  defaultBenefitPackage => ({
                    label: formatMessage({
                      select: defaultBenefitPackage,
                      messages: smeDefaultBenefitPackagesMessages,
                    }),
                    value: defaultBenefitPackage,
                  }),
                )}
              />
              {submissionError && (
                <GraphQlError inModal error={submissionError} />
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...commonMessages.cancel} />
                </Button>
                <Button
                  disabled={!isValid}
                  loading={isSubmitting}
                  text
                  type="submit"
                >
                  <FormattedMessage {...commonMessages.create} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const getCreateBenefitPackageLink = (
  location: RouteComponentProps['location'],
): RouteComponentProps['location'] => ({
  ...location,
  search: qs.stringify({ 'create-benefit-package': true }),
});

export const CreateBenefitPackageAssistChip: React.FC = () => {
  const location = useLocation();
  const isReadonly = useProposalReadonly();

  return (
    <AssistChip
      text={
        <FormattedMessage
          {...smeBenefitPackagesMessages.createBenefitPackage}
        />
      }
      leadingIcon={add}
      to={getCreateBenefitPackageLink(location)}
      disabled={isReadonly}
    />
  );
};
