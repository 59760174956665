import { CheckboxGroup, Chip, Icon, SelectField } from '@frontend/ui';
import { check } from '@frontend/ui/icons';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage, useIntl } from 'components/formats';
import { ScrollableSet } from 'components/scrollable/Set';
import { FilterSection } from 'features/filter-sheet/components/FilterSection';
import { SelectionFilter } from 'features/filter-sheet/components/SelectionFilter';
import { useDebouncedFilters } from 'features/filter-sheet/utils/use-debounced-filters';
import {
  smeCompanyMessages,
  smeLatestProposalStatusMessages,
} from 'features/sme/messages/sme';
import React from 'react';

import { PROPOSAL_STATUS_FILTER_OPTIONS } from '../filters/utils/constants';

export interface Filters {
  salesRep: string;
  status: string[];
}

interface SalesRep {
  externalId: string;
  firstName: string;
  id: string;
  lastName: string;
}

interface Props {
  availableSalesReps: readonly SalesRep[];
  currentAdminId: string;
}

export const Filters: React.FC<Props> = ({
  availableSalesReps,
  currentAdminId,
}) => {
  const { formatMessage } = useIntl();

  const { clearFilters, filters, setFilterValue } =
    useDebouncedFilters<Filters>({
      initialValues: { salesRep: currentAdminId },
    });

  const salesRepOptions = availableSalesReps.map(rep => ({
    label: `${rep.firstName} ${rep.lastName} (${rep.externalId})`,
    value: rep.id,
  }));

  const statusOptions = PROPOSAL_STATUS_FILTER_OPTIONS.map(option => ({
    label: formatMessage({
      select: option,
      messages: smeLatestProposalStatusMessages,
    }),
    value: option,
  }));

  const selectedSalesRep = salesRepOptions?.find(
    s => s.value === filters.salesRep,
  );
  const selectedStatus = filters.status ?? [];

  const hasSalesRepFilter = filters.salesRep != null;
  const currentAdminSelected = filters.salesRep === currentAdminId;

  const filterKeys = Object.keys(filters);

  // Exclude salesRep filter from the count if current admin is selected,
  // as only filters selected in the filter sheet should be counted
  const nFiltersApplied = currentAdminSelected
    ? filterKeys.filter(filter => filter !== 'salesRep').length
    : filterKeys.length;

  return (
    <ChipsWrapper belowSearch>
      <ScrollableSet type="chip">
        <SelectionFilter
          customFilterChips={
            <>
              <Chip
                text={<FormattedMessage {...smeCompanyMessages.all} />}
                leadingIcon={
                  !hasSalesRepFilter ? <Icon icon={check} decorative /> : null
                }
                onClick={() =>
                  setFilterValue('', 'salesRep', {
                    skipDebounce: true,
                  })
                }
                clickable
                selected={!hasSalesRepFilter}
              />
              <Chip
                text={<FormattedMessage {...smeCompanyMessages.myCompanies} />}
                leadingIcon={
                  currentAdminSelected ? <Icon icon={check} decorative /> : null
                }
                onClick={() =>
                  setFilterValue(currentAdminId, 'salesRep', {
                    skipDebounce: true,
                  })
                }
                clickable
                selected={currentAdminSelected}
              />
            </>
          }
          clearFilters={clearFilters}
          nSelected={nFiltersApplied}
        >
          <FilterSection
            header={<FormattedMessage {...smeCompanyMessages.chooseSeller} />}
          >
            <SelectField
              dense
              label={<FormattedMessage {...smeCompanyMessages.seller} />}
              options={salesRepOptions}
              value={selectedSalesRep?.value}
              onChange={e => setFilterValue(e.detail.value, 'salesRep')}
            />
          </FilterSection>
          <FilterSection
            header={<FormattedMessage {...smeCompanyMessages.status} />}
          >
            <CheckboxGroup
              checked={selectedStatus}
              onChange={option => {
                const value = selectedStatus.includes(option.value)
                  ? selectedStatus.filter(status => status !== option.value)
                  : [option.value, ...selectedStatus];

                setFilterValue(value, 'status');
              }}
              options={statusOptions}
            />
          </FilterSection>
        </SelectionFilter>
      </ScrollableSet>
    </ChipsWrapper>
  );
};
