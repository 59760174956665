import { IconButton, Menu, MenuSurfaceAnchor } from '@frontend/ui';
import { moreVert } from '@frontend/ui/icons';
import { a11yMessages } from 'app/messages/a11y';
import { useIntl } from 'components/formats';
import React, { useState } from 'react';
import { MessageDescriptor } from 'react-intl';
import styled from 'styled-components';

const StyledMenu = styled(Menu)`
  margin-top: 3.25rem;
  min-width: 13.375rem;
  a {
    color: ${p => p.theme.onSurface};
    font-weight: normal;

    &:hover {
      text-decoration: none;
    }
  }
`;

interface KebabMenuProps {
  children: React.ReactNode;
  disabled?: boolean;
  fixed?: boolean;
  inClickableArea?: boolean;
  label?: MessageDescriptor | string;
  size?: 'small' | 'default';
}

export const KebabMenu: React.FC<KebabMenuProps> = ({
  inClickableArea,
  fixed,
  label,
  children,
  disabled,
  size = 'default',
}) => {
  const [isOpen, toggleIsOpen] = useState(false);
  const { formatMessage } = useIntl();

  return (
    <MenuSurfaceAnchor>
      <StyledMenu
        onClick={e => {
          if (inClickableArea) {
            e.stopPropagation();
            e.preventDefault();
          }
        }}
        open={isOpen}
        onClose={() => toggleIsOpen(false)}
        fixed={fixed}
        aria-hidden={!isOpen ? 'true' : 'false'}
      >
        {children}
      </StyledMenu>
      <IconButton
        icon={moreVert}
        size={size}
        color="primary"
        label={
          label
            ? typeof label === 'string'
              ? label
              : formatMessage(label)
            : formatMessage(a11yMessages.menu)
        }
        aria-haspopup="true"
        aria-expanded={isOpen}
        onClick={e => {
          if (inClickableArea) {
            e.stopPropagation();
            e.preventDefault();
          }
          toggleIsOpen(!isOpen);
        }}
        disabled={disabled ?? false}
      />
    </MenuSurfaceAnchor>
  );
};
