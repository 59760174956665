import { ApolloError } from '@apollo/client';
import {
  CheckboxField,
  Form,
  NumberField,
  SelectField,
  TextField,
  useFormikContext,
} from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Grid,
  IconButton,
  Section,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { infoOutline } from '@frontend/ui/icons';
import { a11yMessages } from 'app/messages/a11y';
import {
  commonMessages,
  suffixMessages,
  validationMessages,
} from 'app/messages/common';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell50 } from 'components/GridCell';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import { smeEmployeesMessages } from 'features/sme/messages/sme';
import React, { useState } from 'react';
import {
  isEmail,
  isValidEmailDomain,
  isValidPhoneNumber,
  VALID_EMAIL_DOMAINS,
  validateNaturalPersonIdentifier,
} from 'validations';
import * as Yup from 'yup';

import { OwnerDescriptionModal } from '../OwnerDescriptionModal';
import { BenefitPackage, hasFixedPremiumPensionConfig } from './utils';

export interface FormValues {
  benefitPackageId: string;
  email: string;
  firstName: string;
  isOwner: boolean;
  lastName: string;
  monthlyFixedPensionPremium: string;
  monthlySalary: string;
  personalIdentityNumber: string;
  phoneNumber: string;
}

interface Props {
  benefitPackages: readonly BenefitPackage[];
  isSubmitting: boolean;
  isValid: boolean;
  submissionError?: ApolloError;
}

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    benefitPackageId: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    email: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'valid email',
        intl.formatMessage(validationMessages.isValidEmail),
        value => isEmail(value),
      )
      .test(
        'valid email domain',
        intl.formatMessage(validationMessages.invalidEmailDomain, {
          domains: VALID_EMAIL_DOMAINS.join(', '),
        }),
        value => isValidEmailDomain(value),
      ),
    firstName: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    isOwner: Yup.boolean().oneOf(
      [true, false],
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    lastName: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    monthlySalary: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    personalIdentityNumber: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'valid personal identity number',
        intl.formatMessage(validationMessages.invalidPinOrCoordinationNumber),
        value => !value || validateNaturalPersonIdentifier(value),
      ),
    phoneNumber: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'isValidPhone',
        intl.formatMessage(validationMessages.invalidPhoneNumber),
        value => !value || isValidPhoneNumber(value),
      ),
  });

export const EmployeeForm: React.FC<Props> = ({
  benefitPackages,
  isSubmitting,
  isValid,
  submissionError,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { formatMessage } = useIntl();
  const { locale } = useIntlContext();

  const { values } = useFormikContext<FormValues>();

  const selectedBenefitPackage = benefitPackages.find(
    backage => backage.id === values.benefitPackageId,
  );

  const showFixedPremiumField =
    selectedBenefitPackage &&
    hasFixedPremiumPensionConfig(selectedBenefitPackage);

  return (
    <>
      <Form>
        <Section>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...smeEmployeesMessages.personalDetails} />
            </SubsectionHeader>
            <Grid>
              <GridCell50>
                <TextField
                  name="firstName"
                  dense
                  required
                  label={formatMessage(commonMessages.givenName)}
                />
              </GridCell50>
              <GridCell50>
                <TextField
                  name="lastName"
                  dense
                  required
                  label={formatMessage(commonMessages.lastName)}
                />
              </GridCell50>
            </Grid>
            <TextField
              name="personalIdentityNumber"
              dense
              required
              label={formatMessage(smeEmployeesMessages.personalIdentityNumber)}
              helperText={formatMessage(
                smeEmployeesMessages.personalIdentityNumberHelperText,
              )}
            />
            <TextField
              name="email"
              dense
              label={formatMessage(commonMessages.email)}
              required
              type="email"
            />
            <TextField
              name="phoneNumber"
              dense
              label={formatMessage(commonMessages.phone)}
              required
              type="tel"
            />
          </Subsection>
          <Subsection>
            <SubsectionHeader
              iconButton={
                <IconButton
                  onClick={() => setIsModalOpen(true)}
                  label={formatMessage(a11yMessages.information)}
                  icon={infoOutline}
                  size="xsmall"
                />
              }
            >
              <FormattedMessage {...smeEmployeesMessages.isOwner} />
            </SubsectionHeader>
            <CheckboxField
              name="isOwner"
              label={formatMessage(smeEmployeesMessages.isOwnerLabel)}
            />
          </Subsection>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...commonMessages.monthlySalary} />
            </SubsectionHeader>
            <DescriptionWrapper>
              <FormattedMessage
                {...smeEmployeesMessages.monthlySalaryDescription}
              />
            </DescriptionWrapper>
            <NumberField
              dense
              name="monthlySalary"
              label={formatMessage(commonMessages.monthlySalary)}
              affix={formatMessage(suffixMessages.krPerMonth)}
              decimalScale={0}
              locale={locale}
              required
            />
          </Subsection>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...commonMessages.benefitPackage} />
            </SubsectionHeader>
            <SelectField
              name="benefitPackageId"
              dense
              options={benefitPackages.map(benefitPackage => ({
                label: benefitPackage.name,
                value: benefitPackage.id,
              }))}
              label={formatMessage(commonMessages.benefitPackage)}
              required
            />
          </Subsection>
          {showFixedPremiumField && (
            <Subsection>
              <SubsectionHeader>
                <FormattedMessage
                  {...smeEmployeesMessages.monthlyFixedPensionPremiumTitle}
                />
              </SubsectionHeader>
              <DescriptionWrapper>
                <FormattedMessage
                  {...smeEmployeesMessages.monthlyFixedPensionPremiumDescription}
                />
              </DescriptionWrapper>
              <NumberField
                dense
                name="monthlyFixedPensionPremium"
                label={formatMessage(
                  smeEmployeesMessages.monthlyFixedPensionPremium,
                )}
                affix={formatMessage(suffixMessages.krPerMonth)}
                decimalScale={0}
                locale={locale}
              />
            </Subsection>
          )}
          {submissionError && <GraphQlError error={submissionError} />}
          <ButtonLayout>
            <Button
              loading={isSubmitting}
              filled
              type="submit"
              disabled={!isValid}
            >
              <FormattedMessage {...smeEmployeesMessages.save} />
            </Button>
          </ButtonLayout>
        </Section>
      </Form>
      <OwnerDescriptionModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </>
  );
};
