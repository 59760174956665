import { ContentContainer, Grid, Pill } from '@frontend/ui';
import {
  analytics,
  chat,
  loyalty,
  payments,
  people,
  settings,
  swapHorizontalCircle,
  task,
  work,
} from '@frontend/ui/icons';
import {
  companyNotificationsQuery,
  companyNotificationsQueryVariables,
  NotificationStatus,
} from 'app/apollo/graphql/types';
import { companyFeatureMessages } from 'app/messages/company';
import { noteMessages } from 'app/messages/notes';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { GridCell33 } from 'components/GridCell';
import { NavigationCard } from 'components/NavigationCard';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { COMPANY_NOTIFICATIONS_QUERY } from './notification/graphql/queries';

export interface MatchParams {
  companyId: string;
}

export const Company: React.FC = () => {
  const match = useRouteMatch<MatchParams>();

  const { data } = useQuery<
    companyNotificationsQuery,
    companyNotificationsQueryVariables
  >(COMPANY_NOTIFICATIONS_QUERY, {
    variables: {
      companyId: match.params.companyId,
      status: NotificationStatus.OPEN,
    },
    suspend: true,
    errorPolicy: 'all',
  });

  return (
    <ContentContainer>
      <Grid>
        <GridCell33>
          <NavigationCard
            title={
              <FormattedMessage {...companyFeatureMessages.employeesTitle} />
            }
            description={
              <FormattedMessage
                {...companyFeatureMessages.employeesDescription}
              />
            }
            icon={people}
            link={{ to: `${match.url}/employees` }}
          />
        </GridCell33>
        <GridCell33>
          <NavigationCard
            title={
              <FormattedMessage {...companyFeatureMessages.benefitsTitle} />
            }
            description={
              <FormattedMessage
                {...companyFeatureMessages.benefitsDescription}
              />
            }
            icon={loyalty}
            link={{ to: `${match.url}/benefit-packages` }}
          />
        </GridCell33>
        <GridCell33>
          <NavigationCard
            title={
              <FormattedMessage
                {...companyFeatureMessages.salaryExchangesTitle}
              />
            }
            description={
              <FormattedMessage
                {...companyFeatureMessages.salaryExchangesDescription}
              />
            }
            icon={swapHorizontalCircle}
            link={{ to: `${match.url}/salary-exchanges` }}
          />
        </GridCell33>
        <GridCell33>
          <NavigationCard
            title={
              <FormattedMessage {...companyFeatureMessages.invoicesTitle} />
            }
            description={
              <FormattedMessage
                {...companyFeatureMessages.invoicesDescription}
              />
            }
            icon={payments}
            link={{ to: `${match.url}/invoices` }}
          />
        </GridCell33>
        <GridCell33>
          <NavigationCard
            title={
              <FormattedMessage {...companyFeatureMessages.jobOffersTitle} />
            }
            description={
              <FormattedMessage
                {...companyFeatureMessages.jobOffersDescription}
              />
            }
            icon={work}
            link={{ to: `${match.url}/job-offers` }}
          />
        </GridCell33>
        <GridCell33>
          <NavigationCard
            title={
              <FormattedMessage {...companyFeatureMessages.reportsTitle} />
            }
            description={
              <FormattedMessage
                {...companyFeatureMessages.reportsDescription}
              />
            }
            icon={analytics}
            link={{ to: `${match.url}/reports` }}
          />
        </GridCell33>
        <GridCell33>
          <NavigationCard
            title={
              <FormattedMessage {...companyFeatureMessages.settingsTitle} />
            }
            description={
              <FormattedMessage
                {...companyFeatureMessages.settingsDescription}
              />
            }
            icon={settings}
            link={{ to: `${match.url}/settings` }}
          />
        </GridCell33>
        <GridCell33>
          <NavigationCard
            title={
              <FormattedMessage
                {...companyFeatureMessages.notificationsTitle}
              />
            }
            description={
              <FormattedMessage
                {...companyFeatureMessages.notificationsDescription}
              />
            }
            icon={task}
            pill={
              data?.notifications?.totalCount ? (
                <Pill color="error">{data?.notifications.totalCount}</Pill>
              ) : null
            }
            link={{ to: `${match.url}/notifications` }}
          />
        </GridCell33>
        <GridCell33>
          <NavigationCard
            title={<FormattedMessage {...noteMessages.notes} />}
            description={
              <FormattedMessage {...noteMessages.notesDescription} />
            }
            icon={chat}
            link={{ to: `${match.url}/notes` }}
          />
        </GridCell33>
      </Grid>
    </ContentContainer>
  );
};
