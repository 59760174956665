import { Formik } from '@frontend/formik';
import { noteMessages } from 'app/messages/notes';
import { NoteMatchParams } from 'app/pages/companies/company/notes';
import { useIntl } from 'components/formats';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { NoteForm } from '../components/NoteForm';
import { useSubmit } from './use-submit';

export const AddNote: React.FC = () => {
  const { params, url } = useRouteMatch<NoteMatchParams>();

  const { formatMessage } = useIntl();

  const { submit, error: submissionError } = useSubmit();

  return (
    <NavigationAnchor path={url} title={formatMessage(noteMessages.addNote)}>
      <Formik initialValues={{}} onSubmit={submit}>
        {formikProps => (
          <NoteForm
            {...formikProps}
            submissionError={submissionError}
            companyId={params.companyId}
            userAccountId={params.userAccountId}
          />
        )}
      </Formik>
    </NavigationAnchor>
  );
};
