import { formatIdentityNumber } from '@advinans/belt-se-identity-number';
import { Icon } from '@frontend/ui';
import { check } from '@frontend/ui/icons';
import type { ThemeColor } from '@frontend/ui/theme';
import {
  isNumber,
  parseBaseAmountCurrentYear,
  select as _select,
} from '@frontend/utils';
import { BigNumber } from 'bignumber.js';
import { ErrorContext, report } from 'components/ErrorBoundary/lib/report';
import { IconListItem } from 'components/IconListItem';
import { NoValue } from 'components/NoValue';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import { detect as detectBrowser } from 'detect-browser';
import React, { useEffect, useMemo, useState } from 'react';
import {
  FormatNumberOptions,
  // eslint-disable-next-line
  FormattedMessage as IntlMessage,
  FormattedNumber,
  // eslint-disable-next-line
  IntlShape as _IntlShape,
  MessageDescriptor,
  // eslint-disable-next-line
  useIntl as _useIntl,
} from 'react-intl';
import styled from 'styled-components';

import { After, AfterValue } from './constants';

export { FormattedDate, FormattedNumber } from 'react-intl';

export type EmbeddedType = (children: React.ReactNode) => React.JSX.Element;

type ValueType = Record<
  string,
  string | number | React.ReactNode | EmbeddedType | Date | undefined
>;

export interface MessageProps extends MessageDescriptor {
  children?: React.ReactNode;
  values?: ValueType;
}

const Span: EmbeddedType = children => <span lang="sv-SE">{children}</span>;
const Ul: EmbeddedType = children => <ul>{children}</ul>;
const Li: EmbeddedType = children => <li>{children}</li>;
const P: EmbeddedType = children => <p>{children}</p>;
const B: EmbeddedType = children => <b>{children}</b>;
const H1: EmbeddedType = children => (
  <div className="with-text-styles">
    <h1>{children}</h1>
  </div>
);
const H2: EmbeddedType = children => (
  <div className="with-text-styles">
    <h1>{children}</h1>
  </div>
);
const Check: EmbeddedType = children => (
  <IconListItem
    icon={<Icon icon={check} color="green" size="medium" decorative />}
  >
    {children}
  </IconListItem>
);

const Message: React.FC<MessageProps> = ({ children, values, ...props }) => (
  <IntlMessage
    {...props}
    tagName="span"
    values={{
      ...values,
      sv: Span,
      ul: Ul,
      li: Li,
      p: P,
      h1: H1,
      h2: H2,
      check: Check,
      b: B,
    }}
  />
);

interface RecordProps {
  messages: { [key: string]: MessageProps };
  select: string;
  values?: ValueType;
}

export type FormattedMessageProps = MessageProps | RecordProps;

const isSelectMessage = (props: FormattedMessageProps): props is RecordProps =>
  !!(props as RecordProps).select;

export const FormattedMessage: React.FC<FormattedMessageProps> = ({
  ...props
}) => {
  const browser = useMemo(() => detectBrowser(), []);

  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line
      console.error(error);
      if (window.env.ERROR_REPORTING_ACTIVE === 'true') {
        const context: ErrorContext = {
          httpRequest: {
            url: window.location.pathname,
            userAgent: `${browser?.os}, ${browser?.name}@${browser?.version}`,
          },
          user: 'backstage',
        };
        report(error, context);
      }
    }
  }, [error]);

  if (isSelectMessage(props)) {
    const { messages, select } = props;
    const message = messages[select];

    if (!message && !error) {
      setError(
        new Error(
          `Cannot find message with key: ${select} in ${JSON.stringify(
            messages,
          )}`,
        ),
      );
    }

    if (!message) {
      return <span>{select}</span>;
    }

    const _message = { ...message, values: props.values ?? message.values };
    return <Message {..._message} />;
  }
  return <Message {...props} />;
};

const CurrencyWrapper = styled.span<{ color?: ThemeColor }>`
  ${p => p.color && `color: ${p.theme[p.color]}`}
`;

interface FormattedCurrencyProps extends Intl.NumberFormatOptions {
  currency: string;
  value: number | string;
  after?: After;
  color?: ThemeColor;
  compactNotation?: boolean;
  noParse?: boolean;
  noSuffix?: boolean;
  prefix?: React.ReactNode;
}
export const FormattedCurrency: React.FC<FormattedCurrencyProps> = ({
  value,
  currency: _currency,
  noSuffix,
  after,
  prefix,
  color,
  compactNotation,
  noParse,
  maximumFractionDigits,
  minimumFractionDigits,
}) => {
  const valueToNumber = isNumber(value)
    ? value
    : new BigNumber(value).toNumber();

  if (!valueToNumber && valueToNumber !== 0) {
    return <NoValue />;
  }

  const { amount, currency } = parseBaseAmountCurrentYear({
    amount: valueToNumber,
    currency: _currency,
    convertToSek: !noParse,
  });

  if (noSuffix) {
    return (
      <CurrencyWrapper color={color}>
        {prefix && prefix}
        <FormattedNumber
          value={amount}
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
        {after && <AfterValue after={after} />}
      </CurrencyWrapper>
    );
  }

  // XXX: This prevents MSIE11 from incorrectly
  // formatting currencies in SEK as symbol when
  // using Advinans in English. This turns e.g.
  // 'kr500' into 'SEK 500'.
  const { locale } = useIntlContext();
  const currencyDisplay =
    locale === 'en-US' && currency === 'SEK' ? 'code' : 'symbol';

  const compactProps:
    | Pick<
        FormatNumberOptions,
        'notation' | 'compactDisplay' | 'maximumFractionDigits'
      >
    | undefined = compactNotation
    ? {
        notation: 'compact',
        compactDisplay: 'short',
        maximumFractionDigits: 3,
      }
    : {};

  return (
    <CurrencyWrapper color={color}>
      {prefix && prefix}
      <FormattedNumber
        {...compactProps}
        value={amount}
        currencyDisplay={currencyDisplay}
        format="currency"
        currency={currency || 'SEK'}
        maximumFractionDigits={maximumFractionDigits ?? 0}
        minimumFractionDigits={minimumFractionDigits ?? 0}
      />
      {after && <AfterValue after={after} />}
    </CurrencyWrapper>
  );
};

interface FormattedPercentProps {
  value: number | string;
  after?: After;
  color?: ThemeColor;
  integer?: boolean;
  noSuffix?: boolean;
  prefix?: React.ReactNode;
}

export const FormattedPercent: React.FC<FormattedPercentProps> = ({
  value,
  noSuffix,
  after,
  prefix,
  color,
  integer,
}) => {
  const valueToNumber = isNumber(value)
    ? value
    : new BigNumber(value).toNumber();
  if (noSuffix) {
    return (
      <CurrencyWrapper color={color}>
        <FormattedNumber
          value={valueToNumber * 100}
          minimumFractionDigits={integer ? 0 : 2}
          maximumFractionDigits={integer ? 0 : 2}
        />
      </CurrencyWrapper>
    );
  }

  const fractionDigitsProps = integer
    ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
    : {};

  return (
    <CurrencyWrapper color={color}>
      {prefix && <span>{prefix}</span>}
      <FormattedNumber
        value={valueToNumber}
        format="percent"
        {...fractionDigitsProps}
      />
      {after && <AfterValue after={after} />}
    </CurrencyWrapper>
  );
};

interface FormattedPercentIntegerProps {
  value: number | string;
}

export const FormattedPercentInteger: React.FC<
  FormattedPercentIntegerProps
> = ({ value }) => (
  <span>
    {isNumber(value) ? (
      <FormattedNumber value={value} format="percentInteger" />
    ) : null}
  </span>
);

type IntlShapeValueType = Record<
  string,
  string | number | Date | boolean | undefined | null
>;

interface IntlShapeRecordProps {
  messages: { [key: string]: MessageDescriptor };
  select: string;
  values?: IntlShapeValueType;
}

interface IntlShapeMessageDescriptor extends Omit<MessageDescriptor, 'values'> {
  values?: never;
}

const isIntlShapeSelectMessage = (
  props: IntlShapeRecordProps | IntlShapeMessageDescriptor,
): props is IntlShapeRecordProps => !!(props as RecordProps).select;

export interface IntlShape extends Omit<_IntlShape, 'formatMessage'> {
  formatMessage: (
    props: IntlShapeRecordProps | IntlShapeMessageDescriptor,
    values?: IntlShapeValueType,
  ) => string;
}

export const useIntl = (): IntlShape => {
  const { formatMessage, ...intl } = _useIntl();

  const _formatMessage = (
    arg1: IntlShapeRecordProps | IntlShapeMessageDescriptor,
    arg2?: IntlShapeValueType,
  ): string => {
    if (isIntlShapeSelectMessage(arg1)) {
      const { messages, select, values: _values } = arg1;
      const messageDescriptor = _select({
        params: { key: select, record: messages, user: 'backstage' },
        report,
        shouldReportError: window.env.ERROR_REPORTING_ACTIVE === 'true',
      });

      // If failing to retrieve message descriptor from set,
      // fallback to yielding the selector to the user
      if (!messageDescriptor) {
        return select;
      }

      return formatMessage(messageDescriptor, _values);
    }

    return formatMessage(arg1, arg2);
  };

  return { formatMessage: _formatMessage, ...intl };
};

interface FormattedPostalCodeProps {
  value: string;
}

export const FormattedPostalCode: React.FC<FormattedPostalCodeProps> = ({
  value,
  ...props
}) => (
  <span {...props}>
    {value.slice(0, -2)} {value.slice(-2)}
  </span>
);

interface AddressProps {
  address: string;
  city: string;
  postalCode: string;
  careOf?: string | null;
}

export const FormattedAddress: React.FC<AddressProps> = ({
  address,
  careOf,
  postalCode,
  city,
}) => (
  <span style={{ whiteSpace: 'pre-wrap' }}>
    {careOf ? `c/o ${careOf}\n` : ''}
    {`${address}\n`}
    <FormattedPostalCode value={postalCode} /> {city}
  </span>
);

interface PersonalIdentityNumberProps {
  value: string;
}

export const FormattedIdentityNumber: React.FC<PersonalIdentityNumberProps> = ({
  value,
  ...props
}) => <span {...props}>{formatIdentityNumber(value)}</span>;

interface FormattedRangeProps {
  lowerBound: React.ReactNode;
  upperBound?: React.ReactNode;
}

export const FormattedRange: React.FC<FormattedRangeProps> = ({
  lowerBound,
  upperBound,
}) => {
  const _upperBound = upperBound ?? '';
  return (
    <>
      {lowerBound} - {_upperBound}
    </>
  );
};
