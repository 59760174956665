import {
  Icon,
  Illustration,
  Menu,
  MenuChevron,
  MenuItem,
  MenuSurfaceAnchor,
} from '@frontend/ui';
import { arrowDropDown, check } from '@frontend/ui/icons';
import greatBritain from 'assets/illustrations/greatBritain.svg';
import sweden from 'assets/illustrations/sweden.svg';
import { EffectiveLocale } from 'contexts/IntlProviderWrapper';
import React, { useState } from 'react';
import styled from 'styled-components';

const StyledMenu = styled(Menu)`
  margin-top: 3.25rem;
  min-width: 13.375rem;
`;

const SelectToggle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 10rem;
  cursor: pointer;
`;

interface Language {
  icon: string;
  label: string;
  locale: EffectiveLocale;
}

export const LANGUAGES: Language[] = [
  { locale: 'sv-SE', icon: sweden, label: 'Svenska' },
  { locale: 'en-US', icon: greatBritain, label: 'English' },
];

interface Props {
  setLocale: (locale: EffectiveLocale) => void;
  value: EffectiveLocale | undefined;
}

export const LanguageSelector: React.FC<Props> = ({ setLocale, value }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const currentLanguage =
    LANGUAGES.find(language => language.locale === value) ?? LANGUAGES[0];

  const handleClick = selectedLocale => {
    setLocale(selectedLocale);
  };

  return (
    <MenuSurfaceAnchor>
      <StyledMenu open={isMenuOpen} onClose={() => setMenuOpen(false)} fixed>
        {LANGUAGES.map(({ locale, label, icon }) => (
          <MenuItem
            key={locale}
            onClick={() => handleClick(locale)}
            icon={<Illustration size="small" src={icon} />}
            trailingIcon={
              locale === value && (
                <Icon
                  size="medium"
                  icon={check}
                  color="onSurfaceVariant"
                  decorative
                />
              )
            }
          >
            {label}
          </MenuItem>
        ))}
      </StyledMenu>
      <SelectToggle onClick={() => setMenuOpen(!isMenuOpen)}>
        <Illustration
          size="small"
          src={currentLanguage.icon}
          style={{ marginRight: '.625rem' }}
        />
        {currentLanguage.label}
        <MenuChevron
          isOpen={isMenuOpen}
          size="small"
          icon={arrowDropDown}
          style={{ marginLeft: '1rem' }}
        />
      </SelectToggle>
    </MenuSurfaceAnchor>
  );
};
