import { defaultTheme, IconButton, media } from '@frontend/ui';
import { keyboardArrowLeft, keyboardArrowRight } from '@frontend/ui/icons';
import { commonMessages } from 'app/messages/common';
import { useIsSmallScreen } from 'app/utils/use-is-small-screen';
import { useIntl } from 'components/formats';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDebouncedCallback } from 'use-debounce/lib';

import { DISCOUNT_CARD_GUTTER_WIDTH, DISCOUNT_CARD_WIDTH } from '../Card';

export const BACKWARDS_NAV_ID = 'backwards-nav';
export const FORWARDS_NAV_ID = 'forwards-nav';
type Type = 'chip' | 'card';

interface Props {
  type: Type;
  children?: React.ReactNode;
}

const ScrollableWrapper = styled.div<Props>`
  // To prevent the focus indicator from being cut off
  padding-left: 0.375rem;
  padding-top: 0.375rem;
  padding-right: 0.375rem;

  // This padding bottom hack prevents loosing the border bottom
  // on cards in Safari due to the overflow: hidden
  padding-bottom: 0.0625rem;

  display: flex;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  scroll-behavior: smooth;
  position: relative;
  align-items: ${p => (p.type === 'card' ? 'stretch' : 'center')};

  // Hide scrollbars
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollableContainer = styled.div<Props>`
  position: relative;
  display: flex;
  align-items: center;
  ${media.lessThan('tablet')`
    margin: 0 -0.75rem;
    ${ScrollableWrapper} {
      >:first-child {
        margin-left: 0.75rem;
      }

      >:last-child {
        margin-right: 0.75rem;
      }
    }
  `}
`;

const CARD_SCROLL_WIDTH =
  16 * 2 * (DISCOUNT_CARD_WIDTH + DISCOUNT_CARD_GUTTER_WIDTH);

const SCROLL_AMOUNT: Record<Type, number> = {
  chip: 300,
  card: CARD_SCROLL_WIDTH,
};

interface ScrollButtonContainerProps extends Props {
  isLeft?: boolean;
  isSmallScreen?: boolean;
}

const scrollButtonContainerCommonCss = css`
  position: absolute;
  display: flex;
  flex-direction: row;
`;

const ScrollChipButtonContainer = styled.div<ScrollButtonContainerProps>`
  ${scrollButtonContainerCommonCss}
  justify-content: ${p => (p.isLeft ? 'flex-start' : ' flex-end')};
  width: ${p => (p.isSmallScreen ? '1rem' : '5.5rem')};
  ${p => (p.isLeft ? 'left: 0;' : 'right: 0;')}
  ${p => p.isSmallScreen && 'height: 3rem'};
  background: linear-gradient(
    to ${p => (p.isLeft ? 'left' : 'right')},
    rgba(255, 255, 255, 0) 0%,
    ${p => p.theme.surface} ${p => (p.isSmallScreen ? '100%' : '35%')},
    ${p => p.theme.surface} 100%
  );
`;

const ScrollCardButtonContainer = styled.div<ScrollButtonContainerProps>`
  ${scrollButtonContainerCommonCss}
  justify-content: ${p => (p.isLeft ? 'flex-start' : ' flex-end')};
  height: 100%;
  align-items: center;
  background: linear-gradient(
    to ${p => (p.isLeft ? 'left' : 'right')},
    rgba(255, 255, 255, 0) 0%,
    ${p => p.theme.surface} 100%
  );
  ${p =>
    p.isSmallScreen
      ? `
        width: 1.5rem;
        ${p.isLeft ? 'left: 0;' : 'right: 0;'};
        `
      : `
        width: 5rem;
        ${p.isLeft ? 'left: -1.125rem;' : 'right: -1.125rem;'};
        `}

  > button {
    background-color: ${p => p.theme.surface};
    border: 0.0625rem solid ${p => p.theme.surfaceVariant};
  }
`;

const ScrollButtonContainer: React.FC<ScrollButtonContainerProps> = ({
  children,
  ...props
}) =>
  props.type === 'card' ? (
    <ScrollCardButtonContainer {...props}>{children}</ScrollCardButtonContainer>
  ) : (
    <ScrollChipButtonContainer {...props}>{children}</ScrollChipButtonContainer>
  );

export const ScrollableSet: React.FC<Props> = ({ children, type }) => {
  const { formatMessage } = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const [scrollStart, setScrollStart] = useState(false);
  const [scrollEnd, setScrollEnd] = useState(false);
  const [overflowActive, setOverflowActive] = useState(false);
  const isSmallScreen = useIsSmallScreen(defaultTheme.breakpoints.tablet * 16);

  const handleScrollClick = (scrollDirection: number) =>
    ref.current?.scrollTo({
      left: ref.current.scrollLeft + SCROLL_AMOUNT[type] * scrollDirection,
    });

  const handleAction = () => {
    if (!ref.current) {
      return;
    }
    const { scrollLeft, scrollWidth, offsetWidth } = ref.current;
    const _scrollStart = scrollLeft <= 0;
    /* XXX: When the screen width (px) is odd, scrollLeft is off
    by a pixel for offsetWidth + scrollLeft = scrollWidth to add up.
    Therefore, we compensate with an extra pixel in the calcuation.
    */
    const _scrollEnd = scrollWidth - scrollLeft - 1 <= offsetWidth;
    const _overflowActive = offsetWidth < scrollWidth;

    if (_scrollStart !== scrollStart) {
      setScrollStart(_scrollStart);
    }
    if (_scrollEnd !== scrollEnd) {
      setScrollEnd(_scrollEnd);
    }
    if (_overflowActive !== overflowActive) {
      setOverflowActive(_overflowActive);
    }
  };

  const [_handleAction] = useDebouncedCallback(handleAction, 50);

  useEffect(() => {
    handleAction();
    window.addEventListener('resize', _handleAction);
    return () => window.removeEventListener('resize', _handleAction);
  }, [ref.current]);

  useEffect(() => {
    handleAction();
  }, []);

  return (
    <ScrollableContainer type={type}>
      <ScrollableWrapper ref={ref} onScroll={handleAction} type={type}>
        {children}
      </ScrollableWrapper>
      {overflowActive ? (
        <>
          {!scrollStart ? (
            <ScrollButtonContainer
              isLeft
              isSmallScreen={isSmallScreen}
              type={type}
            >
              {!isSmallScreen && (
                <IconButton
                  size={type === 'card' ? 'small' : 'default'}
                  icon={keyboardArrowLeft}
                  color="primary"
                  label={formatMessage(commonMessages.backward)}
                  onClick={() => handleScrollClick(-1)}
                  id={BACKWARDS_NAV_ID}
                />
              )}
            </ScrollButtonContainer>
          ) : null}

          {!scrollEnd ? (
            <ScrollButtonContainer isSmallScreen={isSmallScreen} type={type}>
              {!isSmallScreen && (
                <IconButton
                  size={type === 'card' ? 'small' : 'default'}
                  icon={keyboardArrowRight}
                  color="primary"
                  label={formatMessage(commonMessages.forward)}
                  onClick={() => handleScrollClick(1)}
                  id={FORWARDS_NAV_ID}
                />
              )}
            </ScrollButtonContainer>
          ) : null}
        </>
      ) : null}
    </ScrollableContainer>
  );
};
