import React from 'react';
import styled from 'styled-components';

const Ul = styled.ul`
  padding-inline-start: 1rem;
  margin: 0;

  li:last-child {
    margin-bottom: 0;
  }
`;

interface Props {
  errors: string[];
}

export const ErrorList: React.FC<Props> = ({ errors }) => (
  <Ul>
    {errors.map((error, index) => (
      <li key={index}>{error}</li>
    ))}
  </Ul>
);
