import { Table, TableSortButton, Td, Tfoot, Th, Tr } from '@frontend/ui';
import {
  smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_cost_BenefitPackageCost as Total,
  smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_memberships_ProposalMembership as Employee,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { useTableSort } from 'app/utils/use-table-sort';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { NoValue } from 'components/NoValue';
import { smeCompilationMessages } from 'features/sme/messages/sme';
import React from 'react';

import { by, SortableColumns } from './utils';

const STATIC_COLUMNS = 3;

interface Props {
  employees: readonly Employee[];
  cost?: Total | null;
}

export const EmployeesTable: React.FC<Props> = ({ employees, cost }) => {
  const totalPension = cost?.employersPension ?? cost?.occupationalPension;
  const {
    riskAccident,
    riskHealthcare,
    riskLife,
    riskPensionPremiumWaiver,
    riskTgl,
    riskSickness,
  } = cost ?? {};

  const hasPension = totalPension != null;
  const hasRiskAccident = riskAccident != null;
  const hasRiskHealthcare = riskHealthcare != null;
  const hasRiskLife = riskLife != null;
  const hasRiskPensionPremiumWaiver = riskPensionPremiumWaiver != null;
  const hasRiskTgl = riskTgl != null;
  const hasRiskSickness = riskSickness != null;

  const colSpan =
    [
      hasRiskPensionPremiumWaiver,
      hasRiskSickness,
      hasRiskHealthcare,
      hasRiskAccident,
      hasRiskTgl,
      hasRiskLife,
      hasPension,
    ].filter(Boolean).length + STATIC_COLUMNS;

  const { handleSort, order, orderBy } = useTableSort<SortableColumns>();

  const sortedEmployees =
    order && orderBy ? [...employees].sort(by({ order, orderBy })) : employees;

  return (
    <Table size="xsmall">
      <thead>
        <Tr>
          <Th>
            <TableSortButton
              order={orderBy === 'firstName' ? order : undefined}
              onClick={handleSort}
              value="firstName"
            >
              <FormattedMessage {...commonMessages.name} />
            </TableSortButton>
          </Th>
          <Th align="left" type="number">
            <FormattedMessage {...commonMessages.personalIdentityNumber} />
          </Th>
          <Th type="number">
            <FormattedMessage {...commonMessages.monthlySalary} />
          </Th>
          {hasRiskPensionPremiumWaiver && (
            <Th type="number">
              <FormattedMessage {...smeCompilationMessages.pbfTableHeader} />
            </Th>
          )}
          {hasRiskSickness && (
            <Th type="number">
              <FormattedMessage
                {...smeCompilationMessages.sicknessTableHeader}
              />
            </Th>
          )}
          {hasRiskHealthcare && (
            <Th type="number">
              <FormattedMessage {...smeCompilationMessages.healthTableHeader} />
            </Th>
          )}
          {hasRiskAccident && (
            <Th type="number">
              <FormattedMessage
                {...smeCompilationMessages.accidentTableHeader}
              />
            </Th>
          )}
          {hasRiskTgl && (
            <Th type="number">
              <FormattedMessage {...smeCompilationMessages.tglTableHeader} />
            </Th>
          )}
          {hasRiskLife && (
            <Th type="number">
              <FormattedMessage {...smeCompilationMessages.lifeTableHeader} />
            </Th>
          )}
          {hasPension && (
            <Th type="number">
              <FormattedMessage {...smeCompilationMessages.pensionPremium} />
            </Th>
          )}
          <Th type="number">
            <FormattedMessage {...smeCompilationMessages.total} />
          </Th>
        </Tr>
      </thead>
      <tbody>
        {sortedEmployees.length === 0 ? (
          <Tr>
            <Td colSpan={colSpan}>
              <FormattedMessage {...smeCompilationMessages.noEmployees} />
            </Td>
          </Tr>
        ) : (
          sortedEmployees.map(employee => {
            const pensionCost =
              employee.cost?.employersPension ??
              employee.cost?.occupationalPension;
            return (
              <Tr>
                <Td>{`${employee.firstName} ${employee.lastName}`} </Td>
                <Td align="left" type="number">
                  <FormattedNaturalPersonIdentifier
                    nid={employee.personalIdentityNumber}
                  />
                </Td>
                <Td type="number">
                  {employee.monthlySalary != null ? (
                    <FormattedCurrency
                      currency="SEK"
                      value={employee.monthlySalary}
                    />
                  ) : (
                    <NoValue />
                  )}
                </Td>
                {hasRiskPensionPremiumWaiver && (
                  <Td type="number">
                    {employee.cost?.riskPensionPremiumWaiver != null ? (
                      <FormattedCurrency
                        currency="SEK"
                        value={employee.cost.riskPensionPremiumWaiver}
                      />
                    ) : (
                      <NoValue />
                    )}
                  </Td>
                )}
                {hasRiskSickness && (
                  <Td type="number">
                    {employee.cost?.riskSickness != null ? (
                      <FormattedCurrency
                        currency="SEK"
                        value={employee.cost.riskSickness}
                      />
                    ) : (
                      <NoValue />
                    )}
                  </Td>
                )}
                {hasRiskHealthcare && (
                  <Td type="number">
                    {employee.cost?.riskHealthcare != null ? (
                      <FormattedCurrency
                        currency="SEK"
                        value={employee.cost.riskHealthcare}
                      />
                    ) : (
                      <NoValue />
                    )}
                  </Td>
                )}
                {hasRiskAccident && (
                  <Td type="number">
                    {employee.cost?.riskAccident != null ? (
                      <FormattedCurrency
                        currency="SEK"
                        value={employee.cost.riskAccident}
                      />
                    ) : (
                      <NoValue />
                    )}
                  </Td>
                )}
                {hasRiskTgl && (
                  <Td type="number">
                    {employee.cost?.riskTgl != null ? (
                      <FormattedCurrency
                        currency="SEK"
                        value={employee.cost.riskTgl}
                      />
                    ) : (
                      <NoValue />
                    )}
                  </Td>
                )}
                {hasRiskLife && (
                  <Td type="number">
                    {employee.cost?.riskLife != null ? (
                      <FormattedCurrency
                        currency="SEK"
                        value={employee.cost.riskLife}
                      />
                    ) : (
                      <NoValue />
                    )}
                  </Td>
                )}
                {hasPension && (
                  <Td type="number">
                    {pensionCost != null ? (
                      <FormattedCurrency currency="SEK" value={pensionCost} />
                    ) : (
                      <NoValue />
                    )}
                  </Td>
                )}
                <Td type="number">
                  {employee.cost?.total != null ? (
                    <FormattedCurrency
                      currency="SEK"
                      value={employee.cost.total}
                    />
                  ) : (
                    <NoValue />
                  )}
                </Td>
              </Tr>
            );
          })
        )}
      </tbody>
      <Tfoot>
        <Tr>
          <Td colSpan={STATIC_COLUMNS} />
          {hasRiskPensionPremiumWaiver && (
            <Td type="number">
              {cost?.riskPensionPremiumWaiver != null && (
                <FormattedCurrency
                  currency="SEK"
                  value={cost.riskPensionPremiumWaiver}
                />
              )}
            </Td>
          )}
          {hasRiskSickness && (
            <Td type="number">
              {cost?.riskSickness != null && (
                <FormattedCurrency currency="SEK" value={cost?.riskSickness} />
              )}
            </Td>
          )}
          {hasRiskHealthcare && (
            <Td type="number">
              {cost?.riskHealthcare != null && (
                <FormattedCurrency
                  currency="SEK"
                  value={cost?.riskHealthcare}
                />
              )}
            </Td>
          )}
          {hasRiskAccident && (
            <Td type="number">
              {cost?.riskAccident != null && (
                <FormattedCurrency currency="SEK" value={cost?.riskAccident} />
              )}
            </Td>
          )}
          {hasRiskTgl && (
            <Td type="number">
              {cost?.riskTgl != null && (
                <FormattedCurrency currency="SEK" value={cost?.riskTgl} />
              )}
            </Td>
          )}
          {hasRiskLife && (
            <Td type="number">
              {cost?.riskLife != null && (
                <FormattedCurrency currency="SEK" value={cost?.riskLife} />
              )}
            </Td>
          )}
          {hasPension && (
            <Td type="number">
              {totalPension != null && (
                <FormattedCurrency currency="SEK" value={totalPension} />
              )}
            </Td>
          )}
          <Td align="right" type="number">
            {cost?.total != null ? (
              <FormattedCurrency currency="SEK" value={cost.total} />
            ) : (
              <NoValue />
            )}
          </Td>
        </Tr>
      </Tfoot>
    </Table>
  );
};
