import { Formik } from '@frontend/formik';
import {
  smeCompanyEditHealthcareQuery,
  smeCompanyEditHealthcareQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as BenefitMatchParams } from 'app/pages/sme/company/proposal/benefits/benefit';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import {
  FormValues,
  HealthcareForm,
  validationSchema,
} from '../../form/healthcare';
import { SME_COMPANY_EDIT_HEALTHCARE_QUERY } from './graphql/queries';
import { useSubmit } from './utils/use-submit';

interface Config {
  __typename: string;
}

interface HealthcareConfig {
  __typename: 'ProposalHealthcareBenefitConfig';
}

export const isHealthcareConfig = (
  config: Config,
): config is HealthcareConfig =>
  config.__typename === 'ProposalHealthcareBenefitConfig';

export const EditHealthcare: React.FC = () => {
  const intl = useIntl();
  const { params } = useRouteMatch<BenefitMatchParams>();
  const { submit, submissionError } = useSubmit({ params });

  const { data, loading, error } = useQuery<
    smeCompanyEditHealthcareQuery,
    smeCompanyEditHealthcareQueryVariables
  >(SME_COMPANY_EDIT_HEALTHCARE_QUERY, {
    errorPolicy: 'all',
    variables: { benefitId: params.benefitId, proposalId: params.proposalId },
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.proposal?.benefit) {
    return <EmptyState error={error} />;
  }

  const { benefit, benefitPackages } = data.proposal;

  if (!benefit?.configuration || !isHealthcareConfig(benefit.configuration)) {
    return null;
  }

  const initialValues: FormValues = {
    benefitPackageIds: benefit.benefitPackages.map(({ id }) => id),
    converting: benefit.configuration.converting,
    convertingFrom: benefit.configuration.convertingFrom ?? '',
    level: benefit.configuration.healthcareLevel ?? '',
    waitingPeriod: benefit.configuration.waitingPeriod ? 'true' : 'false',
  };

  return (
    <Formik<FormValues>
      validateOnMount
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={validationSchema(intl)}
    >
      {({ isValid, isSubmitting, values }) => (
        <HealthcareForm
          benefitPackages={benefitPackages}
          isValid={isValid}
          isSubmitting={isSubmitting}
          submissionError={submissionError}
          values={values}
        />
      )}
    </Formik>
  );
};
