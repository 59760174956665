import { Section } from '@frontend/ui';
import {
  noteAuthorsQuery,
  noteAuthorsQueryVariables,
  notesQuery,
  notesQueryVariables,
  SortOrder,
} from 'app/apollo/graphql/types';
import { NoteMatchParams } from 'app/pages/companies/company/notes';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { useTableSort } from 'app/utils/use-table-sort';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import { NOTE_AUTHORS_QUERY, NOTES_QUERY } from './graphql/queries';
import { NotesTable } from './table';
import { useDelete } from './use-delete';
import { updateQuery } from './utils/update-query';

export const Notes: React.FC = () => {
  const location = useLocation();
  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<NoteMatchParams>();

  const { per_page, ...filters } = qs.parse(location.search);

  const { author, categories } = filters;

  const { order, orderBy, handleSort } = useTableSort<string>();

  const {
    data: notesData,
    loading: notesLoading,
    previousData,
    error,
    fetchMore,
  } = useQuery<notesQuery, notesQueryVariables>(NOTES_QUERY, {
    variables: {
      first: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
      companyId: !userAccountId ? companyId : undefined,
      userAccountId,
      categories,
      author: { adminUserExternalId: author },
      sortOrder: (order as unknown as SortOrder) ?? SortOrder.DESC,
    },
  });

  const { data: noteAuthorsData, loading: noteAuthorsLoading } = useQuery<
    noteAuthorsQuery,
    noteAuthorsQueryVariables
  >(NOTE_AUTHORS_QUERY);

  const _data = notesData ?? previousData;
  const notes = _data?.notes?.edges.map(e => e.node) ?? [];
  const pageInfo = _data?.notes?.pageInfo ?? {
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  };

  const {
    _delete: deleteNote,
    loading: deleteLoading,
    error: deleteError,
  } = useDelete();

  if (!_data && notesLoading) {
    return <TopLoading />;
  }

  return (
    <Section>
      {(noteAuthorsLoading || deleteLoading || notesLoading) && <TopLoading />}
      {error && deleteError && <GraphQlError error={error} />}
      <NotesTable
        pageInfo={pageInfo}
        onNextPage={() => {
          fetchMore({
            variables: {
              after: pageInfo.endCursor,
            },
            updateQuery,
          });
        }}
        onPreviousPage={() => {
          fetchMore({
            variables: {
              before: pageInfo.startCursor,
              first: undefined,
              last: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
            },
            updateQuery,
          });
        }}
        notes={notes}
        noteAuthors={noteAuthorsData ? [...noteAuthorsData.noteAuthors] : []}
        sort={{ order, orderBy, handleSort }}
        deleteNote={deleteNote}
      />
    </Section>
  );
};
