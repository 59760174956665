import { ApolloError } from '@apollo/client';
import {
  CheckboxField,
  CheckboxGroupField,
  Form,
  RadioGroupField,
  TextField,
} from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  IconButton,
  Section,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { info } from '@frontend/ui/icons';
import { ProposalHealthcareLevel } from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { validationMessages } from 'app/messages/common';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TextGrid } from 'components/GridCell';
import { NotificationCard } from 'components/NotificationCard';
import {
  smeBenefitFormMessages,
  smeHealthcareLevelMessages,
} from 'features/sme/messages/sme';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { useProposalReadonly } from '../../../../utils/use-proposal-readonly';
import { ConversionModal } from '../ConversionModal';
import { HEALTHCARE_LEVELS } from '../utils/constants';

export interface FormValues {
  benefitPackageIds: string[];
  converting: boolean;
  convertingFrom: string;
  level: ProposalHealthcareLevel | '';
  waitingPeriod: string;
}

interface BenefitPackage {
  id: string;
  name: string;
}

interface Props {
  isSubmitting: boolean;
  isValid: boolean;
  values: FormValues;
  benefitPackages?: readonly BenefitPackage[] | null;
  submissionError?: ApolloError;
}

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    level: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    convertingFrom: Yup.string().when('converting', {
      is: true,
      then: schema =>
        schema.required(intl.formatMessage(validationMessages.mandatoryField)),
    }),
    waitingPeriod: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export const HealthcareForm: React.FC<Props> = ({
  benefitPackages,
  isSubmitting,
  isValid,
  submissionError,
  values,
}) => {
  const [isConversionModalOpen, setIsConversionModalOpen] = useState(false);
  const { formatMessage } = useIntl();
  const disabled = useProposalReadonly();

  return (
    <>
      <Form>
        <Section>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...smeBenefitFormMessages.level} />
            </SubsectionHeader>
            <RadioGroupField
              name="level"
              options={HEALTHCARE_LEVELS.map(level => ({
                label: formatMessage({
                  select: level,
                  messages: smeHealthcareLevelMessages,
                }),
                value: level,
              }))}
              disabled={disabled}
            />
          </Subsection>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...smeBenefitFormMessages.waitingPeriod} />
            </SubsectionHeader>
            <RadioGroupField
              name="waitingPeriod"
              options={[
                {
                  label: formatMessage(smeBenefitFormMessages.waitingPeriodYes),
                  value: 'true',
                },
                {
                  label: formatMessage(smeBenefitFormMessages.waitingPeriodNo),
                  value: 'false',
                },
              ]}
              disabled={disabled}
            />
          </Subsection>
          <Subsection>
            <SubsectionHeader
              iconButton={
                <IconButton
                  onClick={() => setIsConversionModalOpen(true)}
                  label="info"
                  icon={info}
                  size="small"
                  disabled={disabled}
                />
              }
            >
              <FormattedMessage {...smeBenefitFormMessages.conversion} />
            </SubsectionHeader>
            <CheckboxField
              name="converting"
              label={<FormattedMessage {...smeBenefitFormMessages.convert} />}
              disabled={disabled}
            />
            {values.converting && (
              <TextGrid>
                <TextField
                  name="convertingFrom"
                  dense
                  label={
                    <FormattedMessage
                      {...smeBenefitFormMessages.conversionFrom}
                    />
                  }
                  required
                  gridMargin
                  disabled={disabled}
                />
              </TextGrid>
            )}
          </Subsection>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...smeBenefitFormMessages.benefitPackages} />
            </SubsectionHeader>
            {benefitPackages?.length ? (
              <>
                <DescriptionWrapper>
                  <FormattedMessage
                    {...smeBenefitFormMessages.benefitPackagesDescription}
                  />
                </DescriptionWrapper>

                <CheckboxGroupField
                  name="benefitPackageIds"
                  options={benefitPackages.map(benefitPackage => ({
                    label: benefitPackage.name,
                    value: benefitPackage.id,
                  }))}
                  disabled={disabled}
                />
              </>
            ) : (
              <NotificationCard type="warning">
                <FormattedMessage
                  {...commonBenefitMessages.noBenefitPackages}
                />
              </NotificationCard>
            )}
          </Subsection>
          {submissionError && <GraphQlError error={submissionError} />}
          <ButtonLayout>
            <Button
              loading={isSubmitting}
              filled
              type="submit"
              disabled={disabled || !isValid}
            >
              <FormattedMessage {...smeBenefitFormMessages.save} />
            </Button>
          </ButtonLayout>
        </Section>
      </Form>
      <ConversionModal
        isOpen={isConversionModalOpen}
        setIsOpen={setIsConversionModalOpen}
      />
    </>
  );
};
