import { CheckboxField, DatePickerField } from '@frontend/formik';
import { WizardContentCard } from '@frontend/ui';
import { commonMessages, validationMessages } from 'app/messages/common';
import { FormattedMessage, IntlShape } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import startOfMonth from 'date-fns/startOfMonth';
import { PageProps } from 'features/FormikWizard';
import { smeGenerateProposalMessages } from 'features/sme/messages/sme';
import React from 'react';
import * as Yup from 'yup';

import { FormValues as GenerateProposalFormValues } from '../';
import { isProposalDateBeforeStartDate } from '../utils/is-proposal-date-before-start-date';
import { isProposalDateValidInCurrentMonth } from '../utils/is-proposal-date-valid-in-current-month';

export interface FormValues {
  includeIndividualProposal: boolean;
  startDate: string;
}

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    includeIndividualProposal: Yup.boolean().required(
      intl.formatMessage(commonMessages.requiredField),
    ),
    startDate: Yup.string()
      .required(intl.formatMessage(commonMessages.requiredField))
      .test(
        'is-proposal-date-before-start-date',
        intl.formatMessage(validationMessages.earliestPossibleMonth, {
          date: startOfMonth(new Date()),
        }),
        value =>
          !value ||
          isProposalDateBeforeStartDate(
            new Date(value).toISOString(),
            new Date().toISOString(),
          ),
      )
      .test(
        'is-proposal-date-valid-in-current-month',
        intl.formatMessage(validationMessages.chooseLaterMonth, {
          date: startOfMonth(new Date()),
        }),
        value =>
          !value ||
          isProposalDateValidInCurrentMonth(
            new Date(value).toISOString(),
            new Date().toISOString(),
          ),
      ),
  });

export const initialValues: FormValues = {
  includeIndividualProposal: false,
  startDate: '',
};

type Props = GenerateProposalFormValues & PageProps<GenerateProposalFormValues>;

export const ProposalDetails: React.FC<Props> = ({ direction, errors }) => (
  <WizardContentCard
    slideFrom={direction}
    header={
      <FormattedMessage {...smeGenerateProposalMessages.proposalDetailsTitle} />
    }
    description={
      <FormattedMessage
        {...smeGenerateProposalMessages.proposalDetailsDescription}
      />
    }
  >
    <DatePickerField
      dense
      type="month"
      label={<FormattedMessage {...smeGenerateProposalMessages.startDate} />}
      name="startDate"
      required
    />
    <CheckboxField
      label={
        <FormattedMessage
          {...smeGenerateProposalMessages.includeIndividualProposal}
        />
      }
      name="includeIndividualProposal"
    />
    {errors?.submissionError && (
      <NotificationCard type="error">{errors.submissionError}</NotificationCard>
    )}
  </WizardContentCard>
);
